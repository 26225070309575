body {
  @apply text-gray-700 leading-loose bg-gray-50;
  font-family: "Open Sans", sans-serif;
}

.calendar-all .current-month .calendar-heading {
  @apply font-bold text-orange-500;
}

.calendar-single .weekday {
  aspect-ratio: 3 / 1;
}

.calendar-single .week {
  @apply border-t border-r border-gray-100;
}

.calendar-single .week:last-child {
  @apply border-b border-gray-100;
}

.calendar-single .day {
  @apply border-l border-gray-100;
}

.day {
  @apply text-center text-gray-600 grid place-items-center p-1;
  @apply md:items-stretch;
  aspect-ratio: 3 / 2;
}

.day:not(.outside) {
  @apply bg-white;
}

.day-number {
  @apply leading-none w-6 h-6 grid place-items-center text-xs sm:text-sm;
}

.weekday {
  @apply text-center grid place-items-center text-xs text-gray-400;
  aspect-ratio: 2 / 1;
}

.today:not(.outside) .day-number {
  @apply bg-indigo-500 rounded-full text-white;
}

.holiday:not(.outside) {
  @apply bg-orange-100 text-orange-500;
}

.outside {
  @apply text-transparent;
}

/* .holiday.outside {
  @apply text-orange-200;
} */

@media (min-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr 3fr;
  }
}

.header p {
  @apply text-sm sm:text-base;
}

.arrow-button[disabled] {
  @apply text-gray-300 hover:bg-transparent;
}
